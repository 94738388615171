<template>
  <v-container>
    <v-row class="mt-1">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Configurations</h4>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="loading">
      <v-col cols="12">
        <div class="d-flex align-center justify-center flex-column" style="min-height: 300px">
          <v-progress-circular indeterminate size="50" color="grey" />
          <div class="py-2">Loading</div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-expansion-panels class="app-panel-holder">
          <template v-for="service in Object.values(services)">
            <ApplicationSetting
              class="app-panel"
              :key="service.id"
              :service="service"
              :configs="configs[service.id]"
              @change="fetchAllConfigs"
              v-if="configs[service.id] && configs[service.id].length"
            />
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import startCase from "lodash.startcase";
import * as api from "@/constants/api";
import { serviceNames } from "@/constants/serviceNameMap";

export default {
  name: "Configuration",
  metaInfo: { title: "Configuration" },
  components: {
    ApplicationSetting: () => import("@/views/components/Management/ApplicationSetting"),
  },
  data() {
    return {
      loading: true,
      services: {},
      configs: {},
      timeout: null,
    };
  },
  mounted() {
    this.fetchAllConfigs().then(() => (this.loading = false));
    this.timeout = setInterval(() => this.fetchAllConfigs(), 60000);
  },
  destroyed() {
    clearInterval(this.timeout);
  },
  methods: {
    resetService([route, name]) {
      this.$set(this.services, name, {
        id: name,
        title: serviceNames[name] || startCase(name),
        prefix: route.substring(0, route.length - 3),
      });
      this.$set(this.configs, name, this.configs[name] || []);
    },
    async fetchAllConfigs() {
      try {
        this.resetService(["/**", "gateway-service"]);
        const data = await this.$axios.$get(api.ACTUATOR_ROUTES);
        Object.entries(data).forEach(this.resetService);

        const response = await this.$axios.get(api.CONFIG_GET_ALL);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }

        const allConfigs = response.data.data.filter((el) => this.services[el.application]);
        allConfigs.sort((left, right) => {
          if (left.application != right.application) {
            return left.application.localeCompare(right.application);
          }
          if (left.profile != right.profile) {
            return left.profile.localeCompare(right.profile);
          }
          if (left.label != right.label) {
            return left.label.localeCompare(right.label);
          }
          return left.name.localeCompare(right.name);
        });

        const configs = {};
        allConfigs.forEach((el) => {
          configs[el.application] = configs[el.application] || [];
          configs[el.application].push(el);
        });
        this.configs = configs;
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-panel {
  .text--secondary {
    padding: 0.25rem 0;
  }
}
.app-panel-holder {
  margin: 0 auto;
  max-width: 800px !important;
}
</style>

<style lang="scss">
.app-panel-holder {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
